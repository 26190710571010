import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { KTSVG } from '../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { ReactElement } from 'react';

export const EDIT_ICON = 'edit' 
export const DELETE_ICON = 'delete' 
export const HISTORY_ICON = 'history' 
export const LOG_ICON = 'log' 

export enum BUTTON_ICONS {
    EDIT_ICON = '/media/icons/duotune/art/art005.svg',
    DELETE_ICON = '/media/icons/duotune/general/gen027.svg',
    HISTORY_ICON = '/media/icons/duotune/general/gen013.svg',
    LOG_ICON = '/media/icons/duotune/files/fil024.svg',
    SYNC_ICON = '/media/icons/duotune/arrows/arr029.svg'
}

export interface buttonModel {
    key: string,
    function?: VoidFunction,
    icon: string | ReactElement,
    overlay: string
    disabled?: boolean
    hidden?: boolean
    link?: string
    state?: object
    target?: string
}

interface Props {
    buttons: buttonModel[], 
    removeDflex?: boolean
    alignCenter?: boolean
}
const ActionButtons = (props: Props) => {

    const className: string = 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'

    return (
        <td className={`px-4 rounded-end ${props?.removeDflex ? '' : 'd-flex'} ${props?.alignCenter ? 'text-center' : ''}`}  >
            {
                props.buttons.map((button: buttonModel) => {
                    const iconElement: ReactElement = typeof button.icon == 'string' ? <KTSVG path={button.icon} className='svg-icon-3' /> : button.icon
                    return (
                        <OverlayTrigger
                            key={button.key}
                            placement='top'
                            overlay={<Tooltip>{button.overlay ?? ''}</Tooltip>}
                        >
                            {
                                button.link ?  
                                    <Link
                                        type="button"
                                        className={`${className} mb-1`}
                                        to={{
                                            pathname:`${button.link}`
                                        }}
                                        state={button.state}
                                        hidden={button?.hidden}
                                        target={button?.target}
                                    >
                                        { iconElement }
                                    </Link>
                                    : <button
                                        type='button'
                                        className={`${className}`}
                                        onClick={button.function}
                                        hidden={button?.hidden}
                                        disabled={button?.disabled}
                                    >
                                        { iconElement }
                                    </button>
                            }
                        </OverlayTrigger>
                    )
                }) 
            }
        </td>
    )
}

export default ActionButtons